import reportWebVitals from "./reportWebVitals";

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Main from "./main.js";
import { BrowserRouter } from "react-router-dom";
import ReactGA from "react-ga4";

import "./i18n";

ReactGA.initialize("G-WV82TMES5M");

ReactDOM.render(
  <BrowserRouter>
    <Main />
  </BrowserRouter>,
  document.getElementById("root")
);

reportWebVitals();

//G-WV82TMES5M

/*
<!-- Global site tag (gtag.js) - Google Analytics -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-WV82TMES5M"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-WV82TMES5M');
</script>
*/
