import "./contact.scss";

const Contact = () => {
  return (
    <div className="contact">
      <div className="name">Mikko Levonmaa</div>

      <div className="email">mikko.levonmaa@gmail.com</div>
      <div>+358503876605</div>
      <div className="ytunnus">FI3277771-1</div>
    </div>
  );
};

export default Contact;
