export const en = {
  translation: {
    gallery: {
      "01": "Furniture",
      "02": "Unique",
      "03": "Art",
      "04": "Chairs",
      "05": "Timeless items",
      "06": "Simple design",
      "07": "Every day objects",
    },
    menu: {
      home: "Home",
      story: "Story",
      contact: "Contact",
    },
  },
};
