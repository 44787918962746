import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";

// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

import { fi } from "./locales/fi/translation";
import { en } from "./locales/en/translation";

i18n
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "en",
    debug: true,
    resources: {
      fi,
      en,
    },
  });

export default i18n;
