export const fi = {
  translation: {
    gallery: {
      "01": "Huonekaluja",
      "02": "Yksilöllisiä",
      "03": "Taide esineitä",
      "04": "Tuoleja",
      "05": "Ajattomia",
      "06": "Yksinkertaista suunnittelua",
      "07": "Käyttöesineitä",
    },
    menu: {
      home: "Koti",
      story: "Tarinani",
      contact: "Ota yhteytta",
    },
  },
};
