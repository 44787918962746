import "./gallery.scss";
import { useTranslation } from "react-i18next";

const data = [
  {
    name: "gallery.01",
    image: "./images/IMG_3183.png",
  },
  {
    name: "gallery.02",
    image: "./images/IMG_1074.png",
  },
  {
    name: "gallery.03",
    image: "./images/FullSizeRender.png",
  },
  {
    name: "gallery.04",
    image: "./images/tuoli.png",
  },
  {
    name: "gallery.05",
    image: "./images/black_bowl.png",
  },

  {
    name: "gallery.06",
    image: "./images/IMG_9405.png",
  },
  {
    name: "gallery.07",
    image: "./images/tammi_pullo.png",
  },
];

const Gallery = () => {
  const { t } = useTranslation();

  return (
    <div>
      <ul className="list">
        <li className="list_item">
          <div className="list_item__description"></div>
          <div className="list_item__image"></div>
        </li>
        {data.map((item, index) => {
          const displayIndex = index + 1;
          return (
            <li key={`gallery_item_${index}`} className="list_item">
              <div className="list_item__description">
                <span>
                  {displayIndex > 9 ? displayIndex : `0${displayIndex}`}
                </span>
                <span>{t(item.name)}</span>
              </div>
              <div className="list_item__image">
                <img src={item.image} alt={item.name} />
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Gallery;
