import React, { useEffect, useState } from "react";
import "./main.scss";
import Menu2 from "./menu/menu2";
import Gallery from "./gallery/gallery.js";
import Contact from "./contact/contact.js";
import Story from "./story/story.js";
import { FaInstagram } from "react-icons/fa";
import { Routes, Route, useLocation } from "react-router-dom";

import classNames from "classnames";

const Main = () => {
  const [navActive, setNavActive] = useState(false);

  const onMenuClick = () => {
    setNavActive(!navActive);
  };

  const dismissMenu = () => {
    setNavActive(false);
  };

  const location = useLocation();
  useEffect(() => {
    setNavActive(false);
  }, [location]);

  return (
    <div className={classNames({ "nav-active": navActive })}>
      <Menu2 onClick={onMenuClick} onDismiss={dismissMenu} />
      <div className="left"></div>
      <div className="center">
        <Routes>
          <Route path="/" element={<Gallery />} />
          <Route path="/story" element={<Story />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>

      <div className="right">
        {true && (
          <a
            href="https://www.instagram.com/levonkuusi"
            target={"_blank"}
            rel="noreferrer"
          >
            <FaInstagram size={35} color={"rgb(100, 100, 100)"} />
          </a>
        )}
        <div className="logo_text">Levonkuusi</div>
      </div>
    </div>
  );
};

export default Main;
