import "./menu2.scss";

import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const Menu2 = ({ onClick, onDismiss }) => {
  const keyHandler = (event) => {
    if (event.keyCode === 27) {
      // ESC
      onDismiss();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", keyHandler, false);
    return () => {
      document.removeEventListener("keydown", keyHandler, false);
    };
  });
  const { t } = useTranslation();
  return (
    <>
      <div className="menu-icon" onClick={onClick}>
        <span className="menu-icon__line menu-icon__line-left"></span>
        <span className="menu-icon__line"></span>
        <span className="menu-icon__line menu-icon__line-right"></span>
      </div>

      <div className="nav">
        <div className="nav__content">
          <ul className="nav__list">
            <li className="nav__list-item">
              <Link to="/">{t("menu.home")}</Link>
            </li>
            {false && (
              <li className="nav__list-item">
                <Link to="/story">{t("menu.story")}</Link>
              </li>
            )}
            <li className="nav__list-item">
              <Link to="/contact">{t("menu.contact")}</Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Menu2;
